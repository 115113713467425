import React from "react";
import "../../App.css";

// TODO: update this page with some nice style

export default function ContactUs() {
  return (
    <>
      <main className="nav-margin-bottom bg-dark-subtle mt-5">
        <div className="container ">
          <div className="footer-cta pt-5 pb-5">
            <div className="mt-5">
              <h2
                className="fw-bolder text-uppercase justify-content-center"
                style={{ textAlign: "center" }}
              >
                Book an Initial Consultation
              </h2>
              <h4
                className="pt-4 text-white justify-content-center"
                style={{ textAlign: "center" }}
              >
                Want to speak with one of our Education and Migration
                consultants?
              </h4>
              <h4
                className="text-white justify-content-center"
                style={{ textAlign: "center" }}
              >
                Contact us today to discuss your Australian education and
                migration options.
              </h4>
            </div>
            <div className="mt-5">
              <h2
                className="fw-bolder text-capitalize text-success justify-content-center"
                style={{ textAlign: "center" }}
              >
                Meetings are by appointment only.
              </h2>
              <h4
                className=" pt-3 fw-bolder text-capitalize text-success justify-content-center"
                style={{ textAlign: "center" }}
              >
                Contact our office to arrange.{" "}
              </h4>
              <h4
                className="fw-bolder text-capitalize text-success justify-content-center"
                style={{ textAlign: "center" }}
              >
                In-person, Phone, Teams or Zoom consultations are available.
              </h4>
              <p className="pt-2" style={{ textAlign: "center" }}>
                If you need to speak with one of our team members or make an
                appointment please contact us.
              </p>
              <p style={{ textAlign: "center" }}>
                Phone: +61403110910 (Australia) <br />
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; +8801924625914
                (Bangladesh)
              </p>
              <p style={{ textAlign: "center" }}>Email: info@gemcedu.com.au</p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
