import "./FooterOne.css";
import logo from "../../assets/logo/logo.jpeg";
import GoogleMap from "../GoogleMap";
import { Link } from "react-router-dom";

const FooterOne = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-section">
      <div className="container text-white">
        <div className="footer-cta pt-5 pb-2">
          <div className="row">
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="fas fa-map-marker-alt"></i>
                <div className="cta-text" style={{ fontSize: "15px" }}>
                  <h2 className="fw-bold">Find us</h2>
                  <p>
                    <span style={{ color: "burlywood", fontWeight: "bold" }}>
                      Sydney:
                    </span>{" "}
                    12 Osgood Street, Guildford, NSW 2161, Australia <br />
                    <span style={{ color: "burlywood", fontWeight: "bold" }}>
                      Melbourne:
                    </span>{" "}
                    48A Mutton road, Fawkner, VIC 3060, Australia
                  </p>
                  {/* <p>
                    <span style={{ color: "burlywood", fontWeight: "bold" }}>
                      Bangladesh:
                    </span>{" "}
                    2nd Floor, Mahmud Complex, Madina Market, Sylhet
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="fas fa-phone"></i>
                <div className="cta-text" style={{ fontSize: "15px" }}>
                  <h2 className="fw-bold">Call us</h2>
                  <p>
                    <span style={{ color: "burlywood", fontWeight: "bold" }}>
                      Australia:
                    </span>{" "}
                    0403110910, 0410041406, 0483302257
                  </p>
                  <p>
                    <span style={{ color: "burlywood", fontWeight: "bold" }}>
                      Bangladesh:
                    </span>{" "}
                    01924625914
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="far fa-envelope-open"></i>
                <div className="cta-text" style={{ fontSize: "15px" }}>
                  <h2 className="fw-bold">Mail us</h2>
                  <span className="text-white">Email: info@gemcedu.com.au</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-sm-12 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link to="/">
                    <img src={logo} className="img-fluid rounded" alt="logo" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12 mb-50">
              <GoogleMap />
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 text-center text-lg-left">
              <div className="copyright-text">
                <p>Copyright &copy; {currentYear}, All Right Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
